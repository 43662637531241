import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassReducer from 'Redux/Reducers/ClassReducer/classReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_CLASS_LIST, ADD_CLASS, EDIT_CLASS, DELETE_CLASS, EDIT_CLASS_COLOR_WHEEL, SEARCH_CLASS } from 'Actions/constants';
import {
    GetClass_API,
    AddClass_API,
    EditClass_API,
    EditClassColorWheel_API,
    DeleteClass_API,
    SearchClass_API,
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import { UserLoadingReducer } from 'Redux/Reducers/CommonReducer/loadingReducer';


export function* userClassModuleSaga() {
    yield takeLatest(GET_CLASS_LIST, getClassList);
    yield takeLatest(ADD_CLASS, addClass);
    yield takeLatest(EDIT_CLASS, editClass);
    yield takeLatest(DELETE_CLASS, deleteClass);
    yield takeLatest(EDIT_CLASS_COLOR_WHEEL, editClassColorWheel);
    yield takeLatest(SEARCH_CLASS, searchClass);
}

function* getClassList(action) {
    
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const attendanceURL = `${GetClass_API}?role=${data.user_type}&teacher_id=${data.cc_user_id}&class_academic_year=${action.data.class_academic_year}&limit=full&filter_by=${action.data.filter_by}&platform=web`
   let classURL = ''
   if(action.data.limit) {
    classURL = `${GetClass_API}?role=${data.user_type}&teacher_id=${data.cc_user_id}&page=${action.data.page_no}&limit=${action.data.limit}&class_academic_year=${action.data.class_academic_year}&filter_by=${action.data.filter_by}&platform=web`;
   } else {
    classURL = `${GetClass_API}?role=${data.user_type}&teacher_id=${data.cc_user_id}&page=${action.data.page_no}&class_academic_year=${action.data.class_academic_year}&filter_by=${action.data.filter_by}&platform=web`;
   }
    

    const URL = action.data.page_no != null ? classURL : attendanceURL;

    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "filter_by": action.data.filter_by,
        "page_no": action.data.page_no,
        "class_academic_year": action.data.class_academic_year,
    })

    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res)
        
        if (MainDecode.status === 200) {
            yield put(UserClassReducer.getClassListSuccess(MainDecode));
            // yield put(UserLoadingReducer.loadingSuccess(false));

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.getClassListFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.getClassListFailure(false));

    }
}

function* addClass(action) {
    
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // yield put(UserLoadingReducer.loading(true));
    const URL = AddClass_API;
    const BODY = {
        "role" : data.user_type,
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_section": action.data.class_section,
        "class_name": action.data.class_name,
        "class_grade": action.data.class_grade,
        "academic_year_id": action.data.class_academic_year_Id,
        "teacher_id": data.cc_user_id,
        "platform" : "web"
    }
    
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "class_academic_year": action?.data?.year_ids,
                "limit": action?.data?.limit
            }
            yield put(UserClassReducer.addClassSuccess(MainDecode));
            yield put(UserClassReducer.getClassList(data));
            // createNotification('success', MainDecode.message);

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.addClassFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.addClassFailure(false));

    }
}

function* editClass(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditClass_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "role" : data.user_type,
        "teacher_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_section": `${action.data.class_section}`,
        "class_name": action.data.class_name,
        "class_grade": `${action.data.class_grade}`,
        "class_id": action.data.class_id,
        "platform" : 'web',
        "academic_year_id": action.data.class_academic_year_Id,
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "class_academic_year": action.data.class_academic_year_Id,
                "limit": action?.data?.limit
            }
            yield put(UserClassReducer.addClassSuccess(MainDecode));
             // yield put(UserClassReducer.getClassList(data));
             createNotification('success', MainDecode.message);

             if(action.data.fromViewSingleClass) {
                 action.data.history.replace("/home/academic");
             } else {
             yield put(UserClassReducer.getClassList(data));
             }


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.addClassFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.addClassFailure(false));

    }

}

function* deleteClass(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteClass_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        "role" : data.user_type,
        "is_deleted" : 1,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "platform"  :"web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let data = {
                "filter_by": action.data.filter_by,
                "page_no": action.data.page_no,
                "class_academic_year": action.data.class_academic_year_Id,
                "limit" : action?.data?.limit
            }
            yield put(UserClassReducer.deleteClassSuccess(MainDecode));
            // yield put(UserClassReducer.getClassList(data));
            // createNotification('success', MainDecode.message);
            if(action.data.fromViewSingleClass) {
                action.data.history.replace("/home/academic")
            } else {
            yield put(UserClassReducer.getClassList(data));
            }


        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.deleteClassFailure(MainDecode));

        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.deleteClassFailure(false));

    }
}

function* editClassColorWheel(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = EditClassColorWheel_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_color_wheel": action.data.class_color_wheel,
        "class_id": action.data.class_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            yield put(UserClassReducer.editClassColorWheelSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            // if(action?.data?.fromSingleClassView) {
            //     window.location.reload()
            // }
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.editClassColorWheelFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.editClassColorWheelFailure(false));

    }

}

function* searchClass(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${SearchClass_API}?search_string=${action.data.search_string}&academic_year_id=${action.data.academic_year_id}&teacher_id=${data.cc_user_id}&page=${action.data.page_no}&limit=${action.data.limit ? action.data.limit : 'full'}&platform=web`;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "class_id": action.data.class_id,
        "search_string": action.data.search_data,
        // "student_type": action.data.student_type,
        // "academic_year": action.data.academic_year,
        // "search_string" : action.data.search_string,
        "academic_year_id" : 1,
        "Platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        
        if (MainDecode.status === 200) {
            yield put(UserClassReducer.getClassListSuccess(MainDecode));

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserClassReducer.searchClassFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassReducer.searchClassFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}